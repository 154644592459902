import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom/cjs/react-router-dom.min';

const Dashboard = lazy(() => import('../../container/dashboard'));
const Users = lazy(() => import('../../container/usersList'));
const WithdrawalReq = lazy(() => import('../../container/withDrawalsList'));
const payoutReports = lazy(() => import('../../container/payoutReports'));
const Posts = lazy(() => import('../../container/postList'));
const Gyms = lazy(() => import('../../container/gymList'));
const Leaderboard = lazy(() => import('../../container/leaderboardList'));
const ReportPost = lazy(() => import('../../container/reportPost'));
const Profile = lazy(() => import('../../container/profile/myProfile/Index'));
const LeaderboardSettings = lazy(() => import('../../container/settings/LeaderboardSettings'));

const DashboardRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={Dashboard} />
      <Route path={`${path}/users`} component={Users} />
      <Route path={`${path}/withdrawalReq`} component={WithdrawalReq} />
      <Route path={`${path}/payoutReports`} component={payoutReports} />
      <Route path={`${path}/posts`} component={Posts} />
      <Route path={`${path}/gyms`} component={Gyms} />
      <Route path={`${path}/leader-board`} component={Leaderboard} />
      <Route path={`${path}/report-post`} component={ReportPost} />
      <Route path={`${path}/profile/:id`} component={Profile} />
      <Route path={`${path}/leaderboard_setting`} component={LeaderboardSettings} />
    </Switch>
  );
};

export default DashboardRoutes;
