const getItem = (key = 'token', isJson = false) => {
  const data = typeof window !== 'undefined' ? localStorage.getItem(key) : '';
  try {
    if (isJson) return JSON.parse(data);
    else return data;
  } catch (err) {
    return data;
  }
};

const setItem = (key, value, isJson = false) => {
  const stringify = typeof value !== 'string' && isJson ? JSON.stringify(value) : value;
  return localStorage.setItem(key, stringify);
};

const removeItem = key => {
  localStorage.removeItem(key);
};

export { getItem, setItem, removeItem };
